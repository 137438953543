import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  contFooter: {
    ...Commons.flexRow,
    alignItems: "flex-start",
    backgroundColor: colors.black,
    padding: "12px 60px",
    [Commons.smallQuery]: {
      ...Commons.flexColumn,
      padding: "12px",
    },
  },
  hide: {
    display: "none",
  },
  column: {
    ...Commons.flexColumn,
    alignItems: "start",
    flex: 1,
    margin: 30,
    [Commons.smallQuery]: {
      width: "calc(100% - 60px)",
    },
  },
  logo: {
    marginTop: 48,
    cursor: "pointer",
    width: "100%",
    [Commons.smallQuery]: {
      marginTop: 0,
    },
  },
  imgLogo: {
    height: "auto",
    width: "100%",
    objectFit: "contain",
    maxWidth: 420,
  },
  titleColumn: {
    margin: 0,
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 18,
    color: colors.white,
  },
  link: {
    color: colors.white,
    textDecoration: "none",
    cursor: "pointer",
    margin: "6px 0px",
  },
  copyright: {
    backgroundColor: colors.blackLight,
    textAlign: "center",
    color: colors.white,
    fontSize: 18,
    padding: "22px 0 24px 0",
    width: "100%",
    "& br": {
      display: "none",
    },
    [Commons.smallQuery]: {
      "& br": {
        display: "block",
      },
    },
  },
  languageSwitcher: {
    ...Commons.defaultButton,
    border: `3px solid ${colors.themeColor}`,
    borderRadius: 30,
    color: colors.white,
    cursor: "pointer",
    margin: "6px 0px",
    padding: "3px 12px",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: colors.white,
      color: colors.black,
    },
  },
});

export default useStyles;
